<template>
<div>
    <b-card no-body>
        <b-card-header class="email-detail-head">
            <div class="user-details d-flex justify-content-between align-items-center flex-wrap">

                <div class="mail-items">
                    <h5 class="mb-0">
                        {{ message.short_code }}
                    </h5>
                    <b-dropdown variant="link" no-caret toggle-class="p-0" class="email-info-dropup">
                        <template #button-content>
                            <span class="font-small-3 text-muted mr-25">Campaign ID {{ message.campaign_id }}</span>

                        </template>
                    </b-dropdown>
                </div>
            </div>
            <div class="mail-meta-item d-flex align-items-center">
                <small class="mail-date-time text-muted" style="margin-right:20px"> {{ formatDate(message.send_time) }} <br /> Recipients: <strong> {{ message.recipients | formatNumber}} </strong></small>

                <!-- Mail Action DD -->
                <b-dropdown variant="primary" text="Action" right v-show="message.status ==700 || message.status ==400">

                    <b-dropdown-item v-show="message.status ==400" @click="downloadSummary">
                        <feather-icon icon="DownloadIcon" class="mr-50" />
                        <span>Download Summary</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(700)">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit </span>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(506)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Cancel Message</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(200)">
                        <feather-icon icon="ShareIcon" class="mr-50" />
                        <span>Send Now</span>
                    </b-dropdown-item>

                </b-dropdown>

            </div>
        </b-card-header>

        <b-card-body class="mail-message-wrapper">
            <card-analytic-support-tracker :message-data="message" />
            <vue-good-table :columns="columnsSummary" :rows="rowsSummary" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }" >
            </vue-good-table>
            <br />
            <div class="mail-message" v-html="message.message" />

            <!-- eslint-enable -->
        </b-card-body>
        <vue-html2pdf v-show="false" :show-layout="false" :float-layout="true" :enable-download="true" :paginate-elements-by-height="1400" :filename="'Summary_CampaignID_'+message.campaign_id" :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait" ref="html2Pdf" @progress="onProgress($event)" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)">
            <section slot="pdf-content">
                <b-card no-body>
                    <b-card-header class="email-detail-head">
                        <div class="user-details d-flex justify-content-between align-items-center flex-wrap">

                            <div class="mail-items">
                                <h5 class="mb-0">
                                    {{ message.short_code }}
                                </h5>
                                <b-dropdown variant="link" no-caret toggle-class="p-0" class="email-info-dropup">
                                    <template #button-content>
                                        <span class="font-small-3 text-muted mr-25">Campaign ID {{ message.campaign_id }}</span>

                                    </template>
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="mail-meta-item d-flex align-items-center">
                            <small class="mail-date-time text-muted">{{ formatDate(message.send_time) }} <br />Recipients: <strong>{{ message.recipients | formatNumber}}</strong></small>

                            <!-- Mail Action DD -->
                            <b-dropdown variant="link" no-caret toggle-class="p-0" right v-show="message.status ==700 || message.status ==400">
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="17" class="ml-50 text-body" />
                                </template>
                                <b-dropdown-item v-show="message.status ==400" @click="downloadSummary">
                                    <feather-icon icon="DownloadIcon" class="mr-50" />
                                    <span>Download Summary</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(700)">
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Edit </span>
                                </b-dropdown-item>
                                <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(506)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Cancel Message</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-show="message.status ==700" @click="showeditMessage(200)">
                                    <feather-icon icon="ShareIcon" class="mr-50" />
                                    <span>Send Now</span>
                                </b-dropdown-item>

                            </b-dropdown>

                        </div>
                    </b-card-header>
                    <b-card-body class="mail-message-wrapper">
                    <card-analytic-support-tracker :message-data="message" />
                    <vue-good-table :columns="columnsSummary" :rows="rowsSummary" :rtl="direction" :search-options="{
                            enabled: true,
                            externalQuery: searchTerm }" >
                    </vue-good-table>
                    <br />
                    

                    </b-card-body>
                </b-card>
            </section>
        </vue-html2pdf>

    </b-card>
    <b-card>
       
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-2">Search Mobile</label>
                    <b-form-input @keyup.enter="searchMsisdn($event.target.value)" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
            <b-spinner small v-show="isloading" style="margin-left: 30%" /><small v-show="isloading"> Please wait as check for the record...</small>

            <vue-good-table v-if="showSearchTable  && !isloading" :columns="columns" :rows="rowsSearch" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchMobile }" :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"></vue-good-table>

   
        <vue-good-table v-if="!showSearchTable && !isloading" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchMobile }" :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status }}
                    </b-badge>
                </span>

                <!-- Column: Action -->

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card>
    <b-modal v-model="editMessageModal" cancel-variant="outline-secondary" :ok-title="buttonLabel" cancel-title="Close" centered :title="buttonLabel" @ok="editMessage">
        <b-form>
            <b-alert variant="warning" :show="alertMessage !=''">
                <h4 class="alert-heading">
                    {{buttonLabel}}
                </h4>
                <div class="alert-body">
                    {{alertMessage}}
                </div>
            </b-alert>
            <b-alert>
                <div class="alert-body">{{alertMessage}} </div>
            </b-alert>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
            <b-form-group label="Message">
                <b-form-textarea id="textarea-state" v-model="messageText" :state="messageText.length <= 900" placeholder="Enter only 900 characters" rows="3" />
            </b-form-group>
        </b-form>
        <b-col cols="6" class="mb-2" v-show="is_scheduled">
            <b-form-group>
                <small>Schedule Date</small>
                <flat-pickr v-model="scheduleDate" class="form-control" :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}" />
            </b-form-group>
        </b-col>
        <b-col md="6" v-show="is_scheduled">
            <b-form-group>
                <small>Schedule Time</small>
                <flat-pickr v-model="scheduleTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}" />
            </b-form-group>
        </b-col>
    </b-modal>
</div>
</template>

<script>
import {
    BAlert,
    BCol,
    BForm,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BLink,
    BImg,
    BProgress,
    BCardText,
    BFormSelect,
    BPagination,
    BBadge,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner
} from 'bootstrap-vue'
import {
    formatDate
} from '@core/utils/filter'
import CardAnalyticSupportTracker from './CardAnalyticSupportTracker.vue'
import VueHtml2pdf from 'vue-html2pdf'
import {
    VueGoodTable
} from 'vue-good-table'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import $ from 'jquery'
import moment from "moment";
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import numeral from "numeral";

export default {
    components: {
        VueHtml2pdf,
        BAlert,
        BCol,
        BForm,
        CardAnalyticSupportTracker,
        BDropdown,
        BDropdownItem,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BAvatar,
        BLink,
        BImg,
        BProgress,
        BSpinner,
        BCardText,
        VueGoodTable,
        BFormSelect,
        BPagination,
        BBadge,
        BFormTextarea,
        BFormGroup,
        BFormDatepicker,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        flatPickr
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    directives: {
        Ripple,
        heightFade
    },
    watch: {
        message: function (newVal, oldVal) { // watch it

            this.rows = []
            this.rowsSummary = []
            this.getAllSummaryData()
            this.getBulkSummaryStats()
            this.scheduleDate = this.formatDateData(newVal.send_time)
            this.scheduleTime = this.formatTime(newVal.send_time)
            this.status = newVal.status
            this.is_scheduled = newVal.is_scheduled
            this.short_code = newVal.short_code
            this.messageText = newVal.message
            // console.log('Prop changed: ', this.is_scheduled, ' | was: ', oldVal)
        }
    },
    setup() {
        return {
            formatDate,
        }
    },
    data() {
        return {
            status: "",
            is_scheduled: "",
            short_code: "",
            messageText: "",
            scheduleDate: "",
            scheduleTime: "",
            editMessageModal: false,
            pageLength: 3,
            dir: false,
            columns: [{
                    label: 'Phone',
                    field: 'msisdn',
                },
                {
                    label: 'Network',
                    field: 'network'
                },
                {
                    label: 'Status',
                    field: 'delivery',
                },
                {
                    label: 'Date',
                    field: 'created_at',
                },
            ],
            rows: [],
            rowsSearch:[],
            columnsSummary: [{
                    label: 'Status',
                    field: 'description',
                },
                {
                    label: 'Total',
                    field: 'total_count'
                },
            ],
            rowsSummary: [],
            searchTerm: '',
            searchMobile:'',
            status: [{
                    'DeliveredToTerminal': 'Delivered To Terminal',
                    'AbsentSubscriber': 'Absent Subscriber',
                    'SenderName Blacklisted': 'SenderName Blacklisted',
                },
                {
                    'DeliveredToTerminal': 'light-success',
                    'AbsentSubscriber': 'light-danger',
                    'SenderName Blacklisted': 'light-danger',
                }
            ],
            alertMessage: "",
            buttonLabel: "Update",
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
            isloading: false,
            showSearchTable: false
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current: 'light-primary',
                Delivered: 'light-success',
                Failed: 'light-danger',
                Pending: 'light-warning',
                Applied: 'light-info',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    methods: {
        downloadSummary() {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(val) {
            if (val == 70) {
                this.isLoading = true
                $('#download').html(' Please Wait ...');
            } else {
                $('#download').html('Download');
                this.isLoading = false
            }
            console.log(val)
        },
        hasStartedGeneration(val) {

            console.log("Start " + val)

        },
        hasGenerated(val) {
            console.log("End " + val)

        },
        escapeStringHTML(str) {
            str = str.replace(/(<([^>]+)>)/gi, "");
            return str;
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        formatDateData(value) {
            if (!value) return "";
            return moment(value).format("YYYY-MM-DD");
        },
        formatTime(value) {
            if (!value) return "";
            return moment(value).format("HH:mm");
        },
        showeditMessage(status) {
            let vm = this
            if (status == 200) {
                vm.alertMessage = "Are you sure you want to send the message Now?"
                vm.buttonLabel = "Send Message Now"
                vm.is_scheduled = 0
            } else if (status == 506 || status == 505) {
                vm.alertMessage = "Are you sure you want to cancel the message? You will not be able to send this message if you cancel"
                vm.buttonLabel = "Cancel Message"
                vm.is_scheduled = 0
            } else {
                vm.alertMessage = ""
                vm.buttonLabel = "Update Message"
                vm.is_scheduled = 1
            }
            vm.status = status
            vm.editMessageModal = true
        },
        getBulkSummaryStats() {
            let vm = this
            console.log("am here test message")
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_summary/' + vm.message.campaign_id,
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {},
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        vm.rowsSummary = result.summary_analysis_desc
                    }
                },
                error: function (jQxhr, status, error) {

                }
            });
        },
        getAllSummaryData() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_analytics',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    campaignId: vm.message.campaign_id
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        vm.rows = result.summary_data
                    }
                },
                error: function (jQxhr, status, error) {

                }
            });
        },
        searchMsisdn(msisdn){
            let vm = this
            vm.isloading = true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_summary/'+vm.message.campaign_id,
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    msisdn: msisdn
                },
                success: function (response, status, jQxhr) {
                    vm.isloading = false
                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        vm.showSearchTable = true;
                        vm.rowsSearch = result.summary_profile
                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isloading = false
                }
            });
        },
        editMessage() {
            let vm = this
            $.ajax({
                url: store.state.rootUrl + 'sms/v1/bulk/edit_campaign/' + vm.message.campaign_id,
                type: "POST",
                data: JSON.stringify({
                    "shortCode": vm.short_code,
                    "message": vm.escapeStringHTML(vm.messageText),
                    "approval": vm.status,
                    "isScheduled": vm.is_scheduled,
                    "scheduleDate": vm.scheduleDate,
                    "scheduleTime": vm.scheduleTime
                }),
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                cache: false,
                contentType: false,
                processData: false,
                success: function (response, status, jQxhr) {
                    vm.isloading = false
                    if (response.data.code != 200) {
                        console.log("error message " + response.data.message)
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                        vm.$router.push({
                            name: 'bulk-message'
                        });

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isloading = false
                    vm.dismissCountDown = vm.dismissSecs
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        if (jQxhr.responseJSON.data) {
                            vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                        } else {
                            vm.errorMessage = statustext
                        }

                    }
                }
            });

        }

    },
    filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },

    }
}
</script>

<style>

</style>
