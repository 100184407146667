<template>
  <b-card no-body>
    <!-- title and dropdown -->
  
    <!--/ title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>Total Sent: {{ Number(messageData.sent) * Number(messageData.sms_pages) | formatNumber}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>

        <!-- chart -->
        <b-col
          sm="12"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="240"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
           Delivery
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ messageData.delivered | formatNumber }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Pending
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ messageData.pending | formatNumber}}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Failed
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ messageData.failed | formatNumber}}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import numeral from "numeral";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      trackerData: {},
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: ['#2EB62C','#ABE098'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.8,
              gradientToColors: ['#C5E8B7','#57C84D','#83D475','#ABE098','#C5E8B7'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Delivery Rate'],
        },
      },
    }
  },
  props: {
    messageData: Object
  },
   watch: {
    messageData: function (message) {
      this.supportTrackerRadialBar.series =  []
      this.supportTrackerRadialBar.series.push(parseInt(message.delivery_percent))
    }
  },
   filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },

    }
}
</script>
